import { Col, Row } from 'react-bootstrap'

const Show = ({ t, data }) => {

    return (
        <div className='p-4'>
            <div className="text-dark fs-1">
                {t('invoice_details')}
            </div>

            <div className='mt-5'>
                <div className='d-flex'>
                    <img className='rounded-circle' src={data.image} width={45} height={45} alt={data.image} />
                    <span className='mx-2'>
                        <div className='fs-5 fw-bold'>{data.first_name}, {data.last_name}</div>
                        <div>{data.email}</div>
                        <div>{data.phone}</div>
                        <div>{data.adress}</div>
                    </span>
                </div>
                <Row className='mt-5'>
                    <Col xl="4">
                        <div className='text-secondary'>
                            {t('ho_act_facturation_num')}
                        </div>

                        <div className='mt-2'>
                            #{data.booking_number}
                        </div>
                    </Col>

                    <Col xl="4">
                        <div className='text-secondary'>
                            {t('date')}
                        </div>

                        <div className='mt-2'>
                            {data.date}
                        </div>
                    </Col>

                    <Col xl="4">
                        <div className='text-secondary'>
                            {t('status')}
                        </div>

                        <div className={`mt-2 ${data.status ? "text-green" : "text-red"}`}>{data.status ? t('confirmed') : t('cancelled')}</div>
                    </Col>
                </Row>

                <hr className='border-secondary my-3' />

                {
                    data.orders.map(item => (
                        <div className='bg-secondary bg-opacity-10 my-3 p-3'>
                            <Row>
                                <Col xl="6">
                                    <div>{item.name}</div>
                                </Col>
                                <Col xl="2">
                                    <div>{item.price}€</div>
                                </Col>
                                <Col xl="2">
                                    <div>{item.qte}</div>
                                </Col>
                                <Col xl="2">
                                    <div>{item.price * item.qte}€</div>
                                </Col>
                            </Row>
                        </div>
                    ))
                }

                <Row className='justify-content-end mt-4'>
                    <Col xl="3">
                        <div className='text-end'>
                            <div>{t('ho_act_reservation_det_mont')}</div>
                            <div className='fs-2 fw-bold'>{data.price + data.tax}€</div>
                            <div className='text-secondary'>{t('taxes_included')}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Show
