import { Dot, Eye, Trash2 } from "lucide-react";
import { Col, Row } from "react-bootstrap";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from "react";
import bookings from "../../../data/bookings.json";
import RadioInput from "../../../components/forms/RadioInput";
import DateInput from "../../../components/forms/DateInput";
import Show from "./Show";
import Modal from 'react-bootstrap/Modal';

const Listing = ({ t, mode }) => {

    const [filterStatus, setFilterStatus] = useState('all');
    const [selectedBooking, setSelectedBooking] = useState(null);

    const filteredData = bookings.bookings.filter(item => {
        const matchesStatus = filterStatus === 'all' ||
            (filterStatus === 'confirmed' && item.status) ||
            (filterStatus === 'cancelled' && !item.status);
        return matchesStatus;
    });

    const handleClose = () => setSelectedBooking(null);
    const handleShow = (item) => setSelectedBooking(item);

    return (
        <div className={`${mode ? "text-white" : "text-dark"}`}>
            <Row>
                <Col xl="6" className="my-3">
                    <div className='d-flex'>
                        <RadioInput name="booking_filter" label={t('all')} onChange={() => setFilterStatus('all')} checked={filterStatus === 'all'} />
                        <RadioInput name="booking_filter" label={t('confirmed')} onChange={() => setFilterStatus('confirmed')} checked={filterStatus === 'confirmed'} />
                        <RadioInput name="booking_filter" label={t('cancelled')} onChange={() => setFilterStatus('cancelled')} checked={filterStatus === 'cancelled'} />
                        <RadioInput name="booking_filter" label={t('ped_date')} />
                    </div>
                </Col>
                <Col xl="3" className="my-3">
                    <DateInput leftLabel={t('from')} rightLabel={t('to')} />
                </Col>
                <Col xl="3" className="my-3">
                    <button className="btn-blue w-100 p-4">
                        {t('filtrer')}
                    </button>
                </Col>
            </Row>

            {filteredData.map((item, index) => (
                <div key={index} className={`my-3 p-3 rounded ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
                    <Row className="align-items-center">
                        <Col md="2" lg="1" className="my-1">
                            <div className='fs-6 fw-light'>#{item.booking_number}</div>
                        </Col>
                        <Col md="3" lg="3" className="my-1">
                            <div className='fs-6 fw-medium'>{item.first_name} {item.last_name}</div>
                        </Col>

                        <Col xs="4" md="3" lg="3" className="my-1">
                            <div className='fs-6 fw-light'>{item.date}</div>
                        </Col>
                        <Col xs="4" md="3" lg="3" className="my-1">
                            <div role="button" className='d-flex align-items-center' title={item.status ? t('cancelled') : t('confirmed')}>
                                <span className={`${item.status ? "text-green" : "text-red"}`}><Dot size={45} /></span> {item.status ? t('confirmed') : t('cancelled')}
                            </div>
                        </Col>
                        <Col md="2" lg="1" className="my-1">
                            <div className='fs-6 fw-light'>{item.price}€</div>
                        </Col>
                        <Col xs="4" md="4" lg="1" className="product-dropdown d-flex justify-content-end my-1">
                            <NavDropdown
                                title={<div role="button"><HiOutlineEllipsisVertical size={25} /></div>}
                                menuVariant="light"
                            >
                                <span role="button" onClick={() => handleShow(item)} className="d-flex align-items-center px-3 py-2">
                                    <span><Eye size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('see')}</span>
                                </span>
                                <NavDropdown.Divider />
                                <div className="d-flex align-items-center px-3 py-2">
                                    <span className="text-red"><Trash2 size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('delete')}</span>
                                </div>
                            </NavDropdown>
                        </Col>
                    </Row>

                    {selectedBooking && selectedBooking.booking_number === item.booking_number && (
                        <Modal show={true} onHide={handleClose} size="xl">
                            <Show t={t} data={selectedBooking} />
                        </Modal>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Listing;
