import { useTranslation } from "react-i18next";
import Listing from './Listing';

const Details = ({ mode }) => {
    const { t } = useTranslation();
    return (
        <section className='py-3'>
            <h2 className={`fs-3 ${mode ? "text-white" : "text-dark"}`}>{t('my_bookings')}</h2>
            <Listing mode={mode} t={t} />
        </section>
    )
}

export default Details;
